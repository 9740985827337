<template>
  <div class="contact css-helper">
    <div class="container">
      <div class="image contactus-title">联系我们</div>
      <div class="content">
        <div class="item" v-for="(item, index) in items" :key="index">
          <div class="item-title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from "@/components/footer.vue";
export default {
  data() {
    return {
      items: [
        {
          title: "参观咨询",
          text:
            "参观咨询热线：69421242 (09:00-16:00) 投诉电话：69421242 (09:00-16:00)",
        },
        {
          title: "接待集体参观",
          text:
            "学校凭介绍信须提前5天与我院宣教部联系、登记，共同商定参观时间。如预约时超过我院接待能力，再作商定。联系电话（传真）：010－81475286",
        },
        {
          title: "影像授权",
          text:
            "中国大陆：shunyiyingxiang@dpm.org.cn中国港澳台地区及海外：imageserver@dpm.org.cn或传真：81475286转1影像授权：https://www.dpm.org.cn/bottom/apply_image",
        },
        {
          title: "网站维护",
          text:
            "顺义博物院 资料信息部 传真：81475286转1 电子邮件地址：shunyi@dpm.org.cn  ",
        },
        {
          title: "地址",
          text:
            "北京市顺义区石园街道石园南大街顺义文化中心内(南侧第二栋) 邮编：100000   ",
        },
      ],
    };
  },
  components: {
    footerCom,
  },
};
</script>

<style lang="scss" scoped>
.contact {
  padding-top: 107px;
  .container {
    padding-bottom: 100px;
  }
  .contactus-title {
    background-image: url("../assets/intro/title.png");
    color: #b87646;
    font-size: 16px;
    width: 1100px;
    margin: 10px auto;
    text-align: center;
  }

  .content {
    background: url("../assets/intro/content.png") top center no-repeat;
    background-size: 1200px 760px;
    padding: 120px 60px;
    .item {
      color: #333333;
      font-size: 16px;
      width: 69%;
      margin: 0 auto;
      .item-title {
        font-weight: 600;
        margin-bottom: 20px;
      }
      .text {
        margin-bottom: 26px;
        line-height: 30px;
      }
    }
  }
}
</style>